import getApi from 'connect/api';


const hostname = 'https://kakuuchi.site';
const index_url = hostname + '/api/index';

const itemList = async () => {
  const index_response = await getApi(index_url);
  return index_response;
}


export default itemList;
