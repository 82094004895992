// assets
//import { IconDashboard } from '@tabler/icons-react';

// constant
//const icons = { IconDashboard };
//import { Dashboard } from '@mui/icons-material';
//import getApi from 'connect/api';

import itemList from 'utils/itemlist';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = async () => {
  const src = await itemList();
  let dst = {
    id: 'menu',
    title: '目次',
    type: 'group',
    breadcrumbs: false
  };

  let modules = []
  console.log(src);
  //console.log(src.find((element) => element.large_id == 1));
  src.map((large) => {
    modules.push(
      {
        id: `large_module_${large.name}`,
        title: large.name,
        type: 'group',
        breadcrumbs: false,
        children: large.middles.map((middle) => {
          return {
            id: `middle_module_${middle.name}`,
            title: middle.name,
            type: 'group',
            breadcrumbs: false,
            children: middle.articles.map((article) => {
              return {
                id: `article_${large.id}_${middle.id}_${article.id}`,
                title: article.title,
                type: 'item',
                breadcrumbs: false,
                url: `/cv/detail/${large.id}/${middle.id}/${article.id}/${article.title}`,
              }
            })
          }
        })
      }
    )
  });
  dst["children"] = modules;
  return dst;
}

export default dashboard;
