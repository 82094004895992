import axios from 'axios';

const getApi = async (apiEndpoint) => {
  try {
    const response = await axios.get(apiEndpoint);
    return response.data;
  } catch (error) {
    return `Error fetching data from ${apiEndpoint}: ${error.message}`;
  }
};

export default getApi;
