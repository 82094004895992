// assets
import SportsBarIcon from '@mui/icons-material/SportsBar';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: 'FAQ',
  type: 'group',
  children: [
    {
      id: 'util-shadow',
      title: 'What\'s kakuuchi',
      type: 'item',
      url: '/faq/what_is_kakuuchi',
      icon: SportsBarIcon,
      breadcrumbs: false
    }
  ]
};

export default utilities;
